<template>
   <div class="apply">
       <div class="toptit">
           <p><span>企业认证</span>进行企业认证后，您在本平台进行投保或批改时，投保或批改资料无需再进行线下盖章。</p>
       </div>
       <div class="message">
           <div class="flow">
               <div>
                   <img src="../../image/signet/flow1.png" alt="">
                   <p>企业信息认证</p>
               </div>
               <span class="activate"></span>
               <div>
                    <img src="../../image/signet/flow2-1.png" alt="">
                    <p>申请电子签章</p>
               </div>
               <span class="activate"></span>
               <div>
                    <img src="../../image/signet/flow3-1.png" alt="">
                    <p>生成电子签章</p>
               </div>
           </div>
           <div class="imgbox">
               <img src="../../image/signet/create.png" alt="">
               <p>已生成电子签章</p>
           </div>
           <div class="textbox">
               <p>恭喜您，已为企业生成电子签章，签署协议后就可以进行投保、续投、理赔等操作。</p>
           </div>
           <div class="btnbox">
               <el-button type="primary" @click="ok()">好的，我知道了</el-button>
           </div>
       </div>       
   </div>
</template>
<script>    
    export default {
        name: "create",
       
        data() {
            return {
                baseUrl:this.$store.state.baseUrl,
                uid:'',
                name:'',// 公司名称
                checked:false,//是否勾选同意
                   
            }
        },
        mounted() {
            this.uid = this.$route.query.id
            this.name = this.$route.query.name
            console.log(this.uid,this.name)
        },
        methods: {
            ok:function(){
                var userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("Chrome") != -1) {
                    location.href = "about:blank";
                } else {
                    window.opener = null;
                    window.open('', '_self');
                }
                window.close();
            }

        }
    }
</script>
<style scoped lang="scss">
    .apply{
        background: #fff;
        text-align: left;        
        font-size: 12px;        
        font-weight: 400;
        max-width: 1000px;
        margin:0 auto;
        .toptit{
            color: #666666;
            padding:25px 15px;
            border-bottom: 1px solid rgb(238, 240, 242);
            p{
                display: flex;
                align-items: center;
                justify-content:flex-start;
                margin: 0;
            }
            span{
                font-size: 14px;
                font-weight: bold;
                color: #333333; 
                display: inline-block;
                margin: 0 10px;
            }
            p::before{
                content: "";
                width: 3px;
                height: 18px;
                background: #678DF7;
                border-radius: 2px;
                display: inline-block;               
            }
            
        }
        .message{
            padding:30px;            
            .flow{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 50px;
                div{
                    img{
                        width:65px;
                    }
                    p{
                        color: #333333;
                        margin: 3px 0 0;
                    }
                }
                span{
                    flex:1;                    
                    height: 6px;
                    background: #C6C6C6;
                    border-radius: 3px;
                    margin-bottom: 18px;
                }
                span.activate{
                    background: #678DF7;
                }
            }
            .imgbox{
                margin:70px auto 30px;
                text-align: center;
                p{                   
                    font-size: 14px;
                    font-weight: bold;
                    color: #586370; 
                    margin:20px 0 0;
                }
            }
            .textbox{
                max-width: 320px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                text-align: center;
                margin:0 auto;
                line-height: 1.7;
                p{
                    margin: 0;
                }
                span{
                    color:#678DF7;
                }
            }
            .btnbox{
                margin:60px auto 20px;
                text-align: center;
                button{
                    background:#4A76EF
                }
            }

        }
    }
   @media screen and (max-width:575px){
        .apply .message .imgbox{
            margin:30px auto 20px;
            img{
                max-width: 150px;
            }
            p{
                margin: 10px 0 0;
            }
        }
        .apply .message .btnbox{
            margin: 0 auto;
        }
        
    }
</style>